<template>
  <v-card class="mx-auto" max-width="500">
    <v-list shaped three-line>
      <v-list-item-group v-model="selected" multiple>
        <template v-for="(item, i) in items">
          <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item"
            active-class="grey--text text--darken-6"
            disabled
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-left"
                  v-html="
                    `<span class='text-h4 pr-2'>${i +
                      1}.</span> <span class='text-subtitle-1'> ${item}</span>`
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="identity accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selected: []
  }),
  props: {
    items: {
      type: Array,
      required: true
    }
  },

  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.selected = newValue
      }
    }
  }
}
</script>
