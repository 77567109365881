const orgs = [
  { code: 'a', name: 'Tesistan', city: 'Zapopan', avatar: 'site-tesistan.jpg' },
  { code: 'b', name: 'Belenes', city: 'Zapopan', avatar: 'site-belenes.jpg' },
  {
    code: 'c',
    name: 'Cedis',
    city: 'Guadalajara',
    avatar: 'site-cedis-gdl.jpg'
  },
  { code: 'd', name: 'Natco', city: 'Zapopan', avatar: 'site-natco.jpg' }
]

export function orgByCode(code) {
  return orgs.find(e => e.code === code)
}
export function codesOrgs() {
  return orgs.map(e => e.code)
}
export function getOrgs() {
  return orgs
}
