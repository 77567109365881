<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    :transition="dialogTransition"
    v-model="dialog"
    max-width="500px"
  >
    <template>
      <v-card height="100%">
        <v-toolbar color="transparent">
          <v-toolbar-title class="identity--text">{{
            toolbarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fill-height fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="12" class="text-center">
              <v-icon large>{{ item.icon }}</v-icon>
              <div class="d-block text-h2 mb-6">{{ item.fullName }}</div>
              <div class="d-block text-h4">Propósito</div>
              <div class="d-block text-h5 grey--text text--darken-2 mb-6">
                {{ item.fullPurpose }}
              </div>
              <functions-items
                v-if="item.functions.length"
                :items="item.functions"
              ></functions-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { isPhone } from '@/micro/display'
import FunctionsItems from '@/components/orgs/FunctionsItems'
export default {
  components: {
    FunctionsItems
  },
  data: () => ({
    dialog: false
  }),

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  computed: {
    isPhone() {
      return isPhone(this.$vuetify)
    },
    dialogTransition() {
      return this.isPhone ? 'dialog-bottom-transition' : ''
    },
    toolbarTitle() {
      return 'Funciones de mi encargo o rol'
    }
  },

  methods: {
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.v-application .text-h2.spacing {
  letter-spacing: 0.2em !important;
}
</style>
